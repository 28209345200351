


















































































import { Component, Vue } from "vue-property-decorator";
import NutricionistaModule from "@/store/modules/Admin/Nutricionista-module";
import { Nutricionista } from "@/shared/dtos/admin/Nutricionista";
import { Configuracion } from "@/shared/dtos/Configuracion";
import { licencia } from "@/shared/dtos/admin/licencia";
import { DxDateBox } from "devextreme-vue";
import { UtilsNotify } from "@/utils/utils-notify";
import { UtilsDate } from "@/utils/utils-date";
@Component({
  components: { DxDateBox },
  $_veeValidate: { validator: "new" },
})
export default class AddNutricionistaForm extends Vue {
  public nutricionista: Nutricionista = new Nutricionista();
  public insertado: boolean = false;
  created() {
    this.nutricionista.id = -1;
    this.nutricionista.configuracion = new Configuracion();
    this.nutricionista.licencia = new licencia();
    this.nutricionista.licencia.id = -1;
    this.nutricionista.configuracion.id = -1;
    this.nutricionista.licencia.hasta = new Date();
    this.nutricionista.sesion_activa = false;
    this.nutricionista.cuenta_activa = true;
    this.nutricionista.licencia.desde = new Date();
    this.nutricionista.licencia.usada = true;
  }

  Aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.insertado) {
          this.$emit("close");
          return;
        }
        NutricionistaModule.guardarNutricionista(this.nutricionista)
          .then(() => {
            UtilsNotify.NotificacionSuccess(
              "Nutircionista",
              "Se ha creado correctamente el nutricionista"
            );
            this.insertado = true;
            this.$emit("close");
          })
          .catch(() => {
            UtilsNotify.NotificacionDanger(
              "Error",
              "Error al crear el nutricionista en el sistema"
            );
            this.insertado = false;
          });
      }
    });
  }
  public AddDias(dias: number) {
    this.nutricionista.licencia.hasta = UtilsDate.AddDias(
      this.nutricionista.licencia.hasta,
      dias
    );
    this.$forceUpdate();
  }
}
