import { BaseDto } from '@/shared/dtos/base-dto';

export class licencia extends BaseDto {
    public licencia !: string;
    public desde!: Date;
    public hasta!: Date;
    public id_pedido_woocomerce !: number;
    public id_user_woocomerce !: number;
    public usada !: boolean;

} 
